<template>
  <base-map
    :class="
      `${type} leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom`
    "
    :center="center"
    :zoom="zoom"
    @click="onMapClick"
    :defaultMode="calibratingCamera ? 'calibration' : 'active'"
    :suppressModeWarning="!calibratingCamera"
  >
    <camera-calibration-layer
      v-if="calibratingCamera"
      v-bind="{ zoom, sensor, sentry, type, hasCompass }"
    />

    <radar-sector-coverage v-if="type === 'radar'" edit-mode :sensor="sensor" />
    <rf-sector-coverage v-else-if="type === 'rf'" edit-mode :sensor="sensor" />

    <discovair-sector-coverage
      v-else-if="type === 'discovair'"
      edit-mode
      :sensor="sensor"
    />
    <dronesentryx-sector-coverage
      v-else-if="type === 'dronesentryx'"
      edit-mode
      :sensor="sensor"
    />
    <cannon-sector-coverage
      v-else-if="type === 'cannon'"
      edit-mode
      :sensor="sensor"
    />
    <camera-sector-coverage
      v-else-if="type === 'camera'"
      edit-mode
      :sensor="sensor"
    />
    <template v-else>
      <polygon-fog-of-war
        :lat-lngs="range"
        :opacity="0"
        :fill-opacity="0"
        fill-color="black"
        mask="sectorCoverage"
      />
      <polygon-fog-of-war
        :fill-opacity="0"
        :mask-opacity="0.7"
        fill-color="black"
        :opacity="0"
        mask-id="sectorCoverage"
      />
    </template>
    <compass-marker
      v-if="!calibratingCamera"
      :compass="sensor"
      :key="`compass-${sensor.id}-${sensor.sentry_id || 'na'}`"
    />
    <l-marker
      draggable
      :icon="sentryIcon"
      :lat-lng="center"
      @move="onMarkerMove"
    ></l-marker>
  </base-map>
</template>

<script>
import utils from './utils'
import L from 'leaflet'
import BaseMap from './BaseMap'
import { CompassMarker } from './Marker'

import { LMarker } from 'vue2-leaflet'
import { PolygonFogOfWar } from 'vue2-leaflet-fogofwar'

import { mapState, mapActions } from 'vuex'

import {
  RadarSectorCoverage,
  CameraSectorCoverage,
  RfSectorCoverage,
  DiscovairSectorCoverage,
  DronesentryxSectorCoverage,
  CannonSectorCoverage
} from '@/components/Map/Coverage'

import CameraCalibrationLayer from './Detection/CameraCalibrationLayer'

const props = {
  sensor: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0
    })
  },
  sentry: {
    type: Object,
    default: () => ({})
  },
  type: {
    type: String,
    default: 'radar'
  },
  hasCompass: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'EditSensorMap',
  components: {
    RadarSectorCoverage,
    RfSectorCoverage,
    CameraSectorCoverage,
    DiscovairSectorCoverage,
    CompassMarker,
    CannonSectorCoverage,
    BaseMap,
    LMarker,
    PolygonFogOfWar,
    DronesentryxSectorCoverage,
    CameraCalibrationLayer
  },
  props,
  data: () => ({
    zoom: 15
  }),
  provide() {
    return {
      editItemType: this.type
    }
  },
  computed: {
    ...mapState('cameras', ['calibratingCamera']),
    ...mapState('detection', ['selectedDetections']),
    center() {
      return [this.sensor.latitude || 0, this.sensor.longitude || 0]
    },
    range() {
      const { reach, direction = 0, angle = 90 } = this.sensor || {}
      return reach
        ? utils.createRange({ reach, direction, angle, vertex: this.center })
        : []
    },
    sentryIcon() {
      return L.icon({
        iconUrl: require(`@/assets/sentry/green.png`),
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      })
    }
  },
  methods: {
    ...mapActions('detection', ['clearSelectedDetections']),
    onMarkerMove(e) {
      const { latlng } = e || {}
      const { lat, lng } = latlng || {}
      this.sensor.latitude = lat
      this.sensor.longitude = lng
      this.$emit('updateCoordinates', { lat, lng })
    },
    async onMapClick(_, e) {
      if (
        this.selectedDetections.length > 0 &&
        !e.originalEvent.target.classList.contains('leaflet-marker-pane')
      ) {
        this.clearSelectedDetections()
      }
    }
  },
  watch: {
    calibratingCamera(v) {
      if (v) {
        this.zoom = 18
      } else {
        this.zoom = 15
      }
    }
  }
}
</script>
