import { render, staticRenderFns } from "./RfForm.vue?vue&type=template&id=2707aadc&"
import script from "./RfForm.vue?vue&type=script&lang=js&"
export * from "./RfForm.vue?vue&type=script&lang=js&"
import style0 from "./RfForm.vue?vue&type=style&index=0&id=2707aadc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports