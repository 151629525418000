































































































import Vue from 'vue'
import 'leaflet/dist/leaflet.css'
import utils from '@/components/Map/utils'
import { LMap, LMarker, LPolygon } from 'vue2-leaflet'
import { mapState, mapGetters } from 'vuex'
import LTileLayerPlus from '@/components/Map/LTileLayerPlus.vue'
import { icon } from 'leaflet'
import { getTheme } from '@/components/Map/utils/themes'
import { displayRadiatingSector } from '@/components/Map/Detection/radiatingSector'

const props = {
  sector: {
    type: Boolean,
    default: true
  },
  line: {
    type: Boolean,
    default: false
  },
  shown: {
    type: Boolean,
    default: false
  },
  height: {
    type: Number,
    default: 300
  },
  sensor: {
    type: Object,
    default: {}
  },
  showOutsideDetection: {
    //whether to display outside detection or not
    type: Boolean,
    default: false
  }
}

interface ICoordinates {
  lat: number
  lon: number
}

export default Vue.extend({
  name: 'DetectionVisualisation',
  props,
  components: {
    LMap,
    LMarker,
    LPolygon,
    LTileLayerPlus
  },
  data: (): any => {
    return {
      selectedType: 'sector_only',
      zoom: 15,
      attribution: 'DroneShield',
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
        dragging: false,
        maxZoom: 15,
        minZoom: 15,
        doubleClickZoom: false,
        scrollWheelZoom: false
      },
      mapHeight: 80,
      iconSize: [40, 40],
      iconColor: 'green',
      showSectors: true,
      showLine: false,
      offlineFolder: 'satellite',
      isReady: false
    }
  },
  mounted(): void {
    this.showSectors = this.sector
    this.showLine = this.line
    if (this.showSectors && !this.showLine) {
      this.selectedType = 'sector_only'
    } else if (!this.showSectors && this.showLine) {
      this.selectedType = 'line_only'
    } else {
      this.selectedType = 'both'
    }
    this.$nextTick(() => {
      displayRadiatingSector(this.sectorColor)
    })
  },
  computed: {
    ...mapState('system', ['apiUrl']),
    ...mapState('maps', ['activeMapLayers', 'mapLayerID']),
    ...mapState(['theme', 'offline']),
    ...mapGetters('maps', ['activeSiteMapLayer']),
    activeMapLayer() {
      return this.activeSiteMapLayer || {}
    },
    theme() {
      return getTheme(this.themeName)
    },
    mapLayer() {
      return this.offline
        ? ['ESRI World Imagery Topology', 'ESRI World Imagery Satellite'][
            this.mapLayerID % 2
          ]
        : this.activeMapLayers.length
        ? this.activeMapLayers[this.mapLayerID % this.activeMapLayers.length]
        : 'ESRI World Imagery Satellite'
    },
    sectorColor(): string {
      return this.theme.detection.sectorColor
    },
    outsideDetection() {
      return angle => {
        const [min, max] = this.positiveAngle
          ? [45, 45 + angle]
          : [-45 - angle, -45]
        const { latitude, longitude, reach, direction } = this.sensor
        return utils.createRange({
          reach,
          direction,
          min,
          max,
          vertex: [latitude, longitude]
        })
      }
    },
    positiveAngle() {
      return Math.sign(this.angleDifference) > 0
    },
    angleDifference() {
      const { direction: s_deg } = this.sensor
      const d_deg = (this.azimuth_rad * 180) / Math.PI
      return ((d_deg - s_deg + 540) % 360) - 180
    },
    themeName(): string {
      return this.$store.state.theme
    },
    center(): ICoordinates {
      return {
        lat: this.sensor.latitude,
        lon: this.sensor.longitude
      }
    },
    img(): string {
      return `${this.iconColor}.png`
    },
    sentryIcon() {
      return icon({
        iconUrl: require(`@/assets/sentry/${this.img}`),
        iconSize: this.iconSize,
        iconAnchor: [this.iconSize[0] / 2, this.iconSize[1]]
      })
    },
    sensorModel() {
      return this.sensor.model
    }
  },
  methods: {
    sectorPolygon(isLine: boolean, fov: number = 5): number[][] {
      const lat = this.center.lat
      const lng = this.center.lon
      const reach = this.sensor.reach
      let startAngle = this.sensor.direction
      let endAngle = this.sensor.direction

      if (!isLine) {
        startAngle = this.sensor.direction - fov / 2
        endAngle = this.sensor.direction + fov / 2
      }
      return lat
        ? utils.makeArcPath({ lat, lng }, reach, startAngle, endAngle)
        : []
    },
    onResize(): void {
      this.$refs.detmap.mapObject._onResize()
    },
    selectionChanged(): void {
      switch (this.selectedType) {
        case 'sector_only':
          this.showSectors = true
          this.showLine = false
          break
        case 'line_only':
          this.showSectors = false
          this.showLine = true
          break
        case 'both':
          this.showSectors = true
          this.showLine = true
          break
      }
      this.$emit('onChange', {
        show_sectors: this.showSectors,
        show_sector_as_line: this.showLine
      })
    }
  },
  watch: {
    shown: {
      handler: function(): void {
        this.onResize()
      }
    }
  }
})
