<template>
  <router-view />
</template>

<script>
export default {
  name: 'sensors-view',
  computed: {
    activeRouteName() {
      return this.$route['name']
    }
  }
}
</script>
