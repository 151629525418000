<template>
  <!-- <v-tooltip
        max-width="450"
        color="sdark"
        v-if="sensorType === 'radar' && sensor.radar_type === 'radar_one'"
      >
        <v-btn icon light @click="calibrate" slot="activator">
          <v-icon>my_location</v-icon>
        </v-btn>
        <span>
          {{
            "Calibrate by instructing RadarOne to update it's position and orientation using it's internal GPS and compass"
          }}
        </span>
  </v-tooltip>-->
  <v-container fluid fill-height class="editForm">
    <v-layout row wrap>
      <v-flex xs4 class="sensorForm">
        <v-alert
          value="CompassWarning"
          outline
          color="white"
          v-if="sensorType === 'compass'"
        >
          INFO: SmartHub required for installation. Adding GPS Compass directly
          via DroneSentry-C2 can only be completed when GPS Compass is connected
          via SmartHub device. If GPS Compass is connected to RF device, use RF
          device as locator in 'Installation Settings'.
        </v-alert>
        <v-card-title class="px-4 pb-0">
          <div>
            <div class="title primary--text">{{ title }}</div>
          </div>
        </v-card-title>
        <sensor-form
          @onClickSave="$router.back()"
          :sensor="sensorData"
          :type="sensorType"
          :sentry="activePlan ? activeSentry : null"
          :parameters="parameters"
        ></sensor-form>
      </v-flex>
      <v-flex xs8 style="padding-bottom: 70px; padding-right: 60px">
        <edit-sensor-map
          :type="sensorType"
          :center="center"
          @updateCoordinates="$_updateSensorCoord"
          @selectOffset="onSelectOffset"
          :sensor="sensorData"
          :sentry="activeSentry"
          ref="editSensorMap"
        ></edit-sensor-map>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import EditSensorMap from '@/components/Map/EditSensor'
import SensorForm from '@/components/Forms/Sensors/SensorForm'
import schema from '@/components/Forms/Sensors/schema.js'
import { DialogMixin } from '@/components/Mixins'
import {mapGetters, mapActions, mapState} from 'vuex'

const props = {
  id: [String, Number],
  sensorType: String,
  sensor: {
    type: Object,
    default: () => ({})
  },
  serial: {
    type: String,
    default: null
  },
  model: {
    type: String,
    default: null
  },
  parameters: {
    type: Object,
    default: () => ({})
  }
}

export default {
  name: 'edit-sensor',
  components: { EditSensorMap, SensorForm },
  props,
  mixins: [DialogMixin],
  data: () => ({
    sensorData: {},
    center: [0, 0],
    conversionTable: {
      radars: 'radar',
      rf_sensors: 'rf',
      cannons: 'cannon',
      gps_compasses: 'compass',
      cameras: 'camera',
      discovair_sensors: 'discovair',
      dsx_sensors: 'dronesentryx'
    }
  }),
  computed: {
    ...mapGetters('plans', ['activePlan', 'sentryCoords', 'sentrySensorData']),
    ...mapGetters('sentries', ['activeSentry', 'activeSentryId']),
    ...mapGetters('cameras', ['cameraById']),
    ...mapGetters('cameras', ['cameraById']),
    ...mapGetters('rf_sensors', ['rfSensorById', 'dsxSensorById']),
    title() {
      let type = this.sensorType
      switch (type) {
        case 'camera': type = 'optical sensor'
          break;
        case 'dronesentryx': type = 'dronesentry-x'
          break;
        case 'rf': type = 'RF'
          break;
        case 'cannon': type = 'Effector'
          break;
        case 'radar': type = 'Radar'
          break;
        default: return type;
      }

      const action = this.id !== undefined ? 'EDIT' : 'CREATE'
      return `${action} ${(type || '').toUpperCase().replace(/_/g, ' ')}`
    },
    getType() {
      return Object.entries(this.conversionTable).find(
        ([_, v]) => v === this.sensorType
      )[0]
    },
    showFusionDetections() {
      return this.sensorType === 'camera' && this.parameters?.calibrate
    }
  },
  methods: {
    ...mapActions('sensors', ['CLEAR_STATUS']),
    ...mapActions('detection', {enableNewFusionDetections: 'enableNewFusionDetections', disableNewFusionDetections: 'disableNewFusionDetections'}),
    ...mapActions('cameras', { clearCameraStatus: 'CLEAR_STATUS' }),
    ...mapActions('rf_sensors', { clearRfStatus: 'CLEAR_STATUS' }),
    ...mapActions('detection', {enableNewFusionDetections: 'enableNewFusionDetections', disableNewFusionDetections: 'disableNewFusionDetections'}),
    getRawData() {
      const rawData = schema()
      return rawData
        .filter(
          ({ sensors }) =>
            sensors === 'all' ||
            (Array.isArray(sensors) && sensors.includes(this.sensorType))
        )
        .reduce((acc, curr) => {
          acc[curr.key] = curr.value != null ? curr.value : null
          return acc
        }, {})
    },

    onSelectOffset(v) {
      this.sensorData['direction_offset'] = v
    },
    async calibrate() {
      await this.$store.dispatch('sensors/CALIBRATE_RADAR', this.sensor)

      // clear status for save button
      this.dismiss()

      const { latitude, longitude, direction } = this.sensor || {}
      return Object.assign(this.sensorData, { latitude, longitude, direction })
    }
  },
  async mounted() {
    if (this.showFusionDetections) {
      this.enableNewFusionDetections()
    }
  },
  beforeDestroy() {
    this.disableNewFusionDetections()
  },
  async created() {
    this.CLEAR_STATUS()
    this.clearCameraStatus()
    this.clearRfStatus()
    this.sensorData = {}

    let { latitude, longitude } = this.activeSentry || {}
    if (this.activePlan) {
      ;[latitude, longitude] = this.sentryCoords(this.activeSentryId) || [0, 0]
    }

    if (this.id !== undefined) {
      // clear status for save button
      this.$_dismiss()

      if (this.activePlan) {
        this.sensorData = { ...this.sentrySensorData(this.activeSentryId, this.getType, this.id) }
      } else if (this.sensorType === 'camera') {
        this.sensorData = { ...this.cameraById(this.id) }
      } else if (this.sensorType === 'rf')  {
        this.sensorData = { ...this.rfSensorById(this.id) }
      } else if (this.sensorType === 'dronesentryx')  {
        this.sensorData = { ...this.dsxSensorById(this.id) }
      } else {
        this.sensorData = {...this.$store.getters[`sensors/${this.getType}ById`](this.id) }
      }
    } else {
      const rawData = this.getRawData()

      this.sensorData = Object.assign(rawData, {
        latitude,
        longitude,
        sentry_id: this.activeSentryId,
        ...(this.serial ? { serial_number: this.serial } : {}),
        ...(this.model ? { model: this.model } : {})
      })
    }

    this.center = [latitude, longitude]
  }
}
</script>
<style scoped>
.editForm {
  position: fixed;
  padding: 0px;
}
</style>
