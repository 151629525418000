<template>
  <v-card-title>
    <span class="subheading">{{ label }}</span>
    <v-divider :class="dividerClass"></v-divider>
  </v-card-title>
</template>

<script>
const props = {
  label: {
    type: String,
    default: ""
  },
  dividerClass: {
    type: String,
    default: "ml-3"
  }
}

export default {
  name: 'SubHeader',
  props: props
}
</script>

<style scoped>
.subheading {
  margin-left: -1.25rem;
}
</style>