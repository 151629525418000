<template>
  <div>
    <p :class="`${color}--text`">
      <v-progress-circular
        indeterminate
        :color="color"
        :size="20"
        :width="2"
        v-if="loading"
        class="progress"
      ></v-progress-circular
      ><v-icon
        class="mr-1"
        :color="color"
        v-else
        style="vertical-align: middle"
        >{{
          ticked ? 'check_circle_outline' : 'radio_button_unchecked'
        }}</v-icon
      >
      {{ label }}
    </p>
    <p
      :class="
        `calibrationStep ${disabled ? (ticked ? 'dropOut' : '') : 'dropIn'}`
      "
    >
      <v-alert :value="error" outline color="red" type="error" class="my-3">
        The detection was lost, please try again.
      </v-alert>
      {{ hint }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CameraCalibrationStep',
  props: {
    label: String,
    hint: String,
    disabled: {
      type: Boolean,
      default: false
    },
    ticked: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color() {
      return this.disabled ? (this.ticked ? 'white' : 'grey') : 'primary'
    },
    loading() {
      return !this.disabled && !this.ticked
    }
  }
}
</script>
<style scoped>
.progress {
  margin-left: 2.5px;
  margin-bottom: 2.5px;
  margin-top: 1px;
  margin-right: 5.5px;
}
.calibrationStep {
  max-height: 0px;
  visibility: hidden;
  margin: 0px 15px 0px 15px;
  transform: translate(-10px, 0px);
}
.calibrationStep.dropIn {
  animation: dropIn 1s 2s forwards;
}
.calibrationStep.dropOut {
  animation: dropOut 1s 0.5s both;
}
@keyframes dropIn {
  from {
    max-height: 0px;
    visibility: visible;
    opacity: 0;
    margin: 0px 15px 0px 15px;
    transform: translate(-10px, 0px);
  }
  to {
    opacity: 1;
    visibility: visible;
    max-height: 100px;
    margin: 0px 15px 15px 15px;
    transform: translate(0px, 0px);
  }
}

@keyframes dropOut {
  from {
    opacity: 1;
    visibility: visible;
    max-height: 100px;
    margin: 0px 15px 15px 15px;
    transform: translate(0px, 0px);
  }
  to {
    opacity: 0;
    visibility: visible;
    max-height: 0px;
    margin: 0px 15px 0px 15px;
    transform: translate(-10px, 0px);
  }
}
</style>
