var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{staticClass:"mb-3 mt-0",attrs:{"value":true,"outline":"","color":"primary","type":"warning"}},[_vm._v(" Radars must be configured prior to calibrating the camera. ")]),_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-input',{staticStyle:{"position":"absolute"},attrs:{"error":_vm.calibratingCamera !== 'cal-4-0' || _vm.disableSubmit}}),_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1"}},[_vm._v(" Target Acquisition ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('camera-calibration-step',{attrs:{"label":("Fly " + (_vm.convertValIfImperial(_vm.systemSetting, 150, 'm') +
            _vm.convertUnitIfImperial(_vm.systemSetting, 'm')) + " away,\n                 " + (_vm.convertValIfImperial(_vm.systemSetting, 25, 'm') +
                   _vm.convertUnitIfImperial(_vm.systemSetting, 'm')) + " high"),"disabled":!_vm.allowCalibration || _vm.step != 1 || _vm.subStep != 0,"ticked":_vm.step > 1 || _vm.subStep > 0,"hint":"Hover the drone within the green band in the direction of the radar to get a consistent detection.","error":_vm.error.radar}}),_c('camera-calibration-step',{attrs:{"label":"Control the camera and find the drone","disabled":_vm.step != 1 || _vm.subStep != 1,"ticked":_vm.step > 1 || _vm.subStep > 1,"hint":"Aim at the drone and zoom in to get it centred in the camera feed.","error":_vm.error.camera}})],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v("Tilt Calibration ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('camera-calibration-step',{attrs:{"label":("Fly to " + (_vm.convertValIfImperial(_vm.systemSetting, _vm.targetAltitude, 'm') +
            _vm.convertUnitIfImperial(_vm.systemSetting, 'm')) + " high"),"disabled":_vm.step != 2 || _vm.subStep != 0,"ticked":_vm.step > 2 || _vm.subStep > 0,"hint":"Move up slowly at constant speed as the camera tracks the drone."}}),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('Plotly',{attrs:{"data":[
              Object.assign({}, _vm.altitudeData,
                {mode: 'markers',
                type: 'scattergl',
                line: {
                  color: '#bcbd22',
                  width: 1
                },
                marker: {
                  size: 3
                },
                hoverinfo: 'skip'})
            ],"layout":{
              autosize: true,
              margin: {
                l: 45,
                r: 10,
                b: 20,
                t: 20
              },
              paper_bgcolor: 'rgba(0,0,0,0)',
              plot_bgcolor: 'rgba(0,0,0,0)',
              showlegend: false,
              yaxis: {
                showgrid: true,
                range: [_vm.radarAltitudeAxis.min, _vm.radarAltitudeAxis.max],
                color: '#ccc',
                gridcolor: 'rgba(0,0,0,0.1)',
                fixedrange: true,
                title: { text: 'Altitude' },
                zeroline: false
              },
              xaxis: {
                showgrid: false,
                fixedrange: true,
                showticklabels: false,
                zeroline: false
              },
              shapes: [
                {
                  type: 'rect',
                  xref: 'paper',
                  yref: 'y',
                  x0: 0,
                  y0: _vm.targetAltitude - 2,
                  x1: 1,
                  y1: _vm.targetAltitude + 2,
                  fillcolor: '#4CAF50',
                  opacity: 0.1,
                  line: { width: 0 }
                },
                {
                  type: 'rect',
                  xref: 'paper',
                  yref: 'y',
                  x0: 0,
                  y0: _vm.targetAltitude - 52,
                  x1: 1,
                  y1: _vm.targetAltitude - 48,
                  fillcolor: '#bcbd22',
                  opacity: 0.1,
                  line: { width: 0 }
                }
              ]
            },"display-mode-bar":false}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('Plotly',{attrs:{"data":[
              Object.assign({}, _vm.tiltData,
                {mode: 'markers',
                type: 'scattergl',
                line: {
                  color: '#bcbd22',
                  width: 1
                },
                marker: {
                  size: 3
                },
                hoverinfo: 'skip'})
            ],"layout":{
              autosize: true,
              margin: {
                l: 45,
                r: 10,
                b: 45,
                t: 20
              },
              paper_bgcolor: 'rgba(0,0,0,0)',
              plot_bgcolor: 'rgba(0,0,0,0)',
              showlegend: false,
              yaxis: {
                showgrid: true,
                fixedrange: true,
                range: [-1, 1],
                color: '#ccc',
                zeroline: false,
                gridcolor: 'rgba(0,0,0,0.1)',
                title: { text: 'Camera tilt' }
              },
              xaxis: {
                showgrid: true,
                fixedrange: true,
                range: [
                  _vm.tiltCalibrationResult.min || -1,
                  _vm.tiltCalibrationResult.max || 1
                ],
                color: '#ccc',
                zeroline: false,
                gridcolor: 'rgba(0,0,0,0.1)',
                title: { text: 'Radar Detection Elevation' }
              },
              shapes: [
                {
                  type: 'line',
                  x0: _vm.tiltCalibrationResult.min || -1,
                  y0: -1,
                  x1: _vm.tiltCalibrationResult.max || 1,
                  y1: 1,
                  color: '#4CAF50',
                  line: {
                    width: 10,
                    color: 'rgba(188,189,34,0.1)'
                  }
                }
              ]
            },"display-mode-bar":false}})],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 3,"step":"3"}},[_vm._v("Pan Calibration")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('camera-calibration-step',{attrs:{"label":"Fly 30 degrees sideways","disabled":_vm.step != 3 || _vm.subStep != 0,"ticked":_vm.step > 3 || _vm.subStep > 0,"hint":"Move left or right slowly at constant speed as the camera tracks the drone."}}),_c('Plotly',{attrs:{"data":[
          {
            x: _vm.panData,
            y: new Array(_vm.panData.length).fill(0),
            mode: 'markers',
            type: 'scattergl',
            marker: {
              color: '#bcbd22',
              size: 15
            },
            hoverinfo: 'skip'
          }
        ],"layout":{
          autosize: true,
          height: 100,
          margin: {
            l: 45,
            r: 10,
            b: 45,
            t: 20
          },
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          showlegend: false,
          yaxis: {
            showgrid: false,
            showticklabels: false,
            fixedrange: true,
            range: [-1, 1],
            zeroline: false,
            gridcolor: 'rgba(0,0,0,0.1)'
          },
          xaxis: {
            showgrid: true,
            fixedrange: true,
            range: _vm.cameraPanChartRange,
            color: '#ccc',
            zeroline: false,
            gridcolor: 'rgba(0,0,0,0.1)',
            title: { text: 'Camera Azimuth Offset' }
          },
          shapes: [
            {
              type: 'rect',
              xref: 'x',
              yref: 'paper',
              x0: -180,
              y0: 0,
              x1: -29,
              y1: 1,
              fillcolor: '#4CAF50',
              opacity: 0.2,
              line: { width: 0 }
            },
            {
              type: 'rect',
              xref: 'x',
              yref: 'paper',
              x0: 29,
              y0: 0,
              x1: 180,
              y1: 1,
              fillcolor: '#4CAF50',
              opacity: 0.2,
              line: { width: 0 }
            }
          ]
        },"display-mode-bar":false}})],1),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v("Calibration Check")]),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('camera-calibration-step',{attrs:{"label":"Validate the calibration","disabled":_vm.step != 4 || _vm.subStep != 0,"ticked":true,"hint":"Check the calibration values below and click submit to apply."}}),_c('v-chip',{attrs:{"color":"rgba(255,167,38,0.4)","outline":"","text-color":"white"}},[_c('v-avatar',{attrs:{"color":"rgba(255,167,38,0.2)"}},[_vm._v("min")]),_vm._v(" "+_vm._s(_vm.tiltCalibrationResult.min)+" ")],1),_c('v-chip',{attrs:{"color":"rgba(255,167,38,0.4)","outline":"","text-color":"white"}},[_c('v-avatar',{attrs:{"color":"rgba(255,167,38,0.2)"}},[_vm._v("max")]),_vm._v(" "+_vm._s(_vm.tiltCalibrationResult.max)+" ")],1),_c('v-chip',{attrs:{"color":"rgba(255,167,38,0.4)","outline":"","text-color":"white"}},[_c('v-avatar',{attrs:{"color":"rgba(255,167,38,0.2)"}},[_vm._v("dir")]),_vm._v(" "+_vm._s(_vm.panCalibrationResult)+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }