<template>
  <d-form
    v-model="valid"
    @onCancel="
      $router.back()
      $emit('onCancel')
    "
    includeCancel
    @onSubmit="$emit('onSubmit')"
    v-bind="{ error, status }"
    dataCy="dsx-form"
  >
    <template v-slot:general>
      <v-select
        v-if="showInstallationSelector"
        label="Installation"
        :items="sentries"
        item-text="name"
        item-value="id"
        ref="installationSelect"
        v-model="sensor.sentry_id"
        :disabled="!authorizeEdit"
        @change="$_updatedSentry"
        :error="selectInstallationError"
        :suffix="selecInstallationSuffix"
      ></v-select>
      <v-text-field
        label="Name"
        ref="nameField"
        autofocus
        v-model.trim="sensor.name"
        :disabled="!authorizeEdit"
        :rules="[rules.required]"
      />
      <div data-cy="dsx-type">
        <v-select
          label="DroneSentry-X Model"
          :items="sensorTypes"
          v-model="sensor.model"
          @change="DSXTypeChanged"
          :disabled="!authorizeEdit"
        />
      </div>
      <v-text-field
        label="Serial Number"
        v-model.trim="sensor.serial_number"
        v-if="!activePlan"
        :disabled="!authorizeEdit"
        :rules="[rules.required, rules.serialNumberLength]"
      />
      <d-param-slider
        v-if="!activePlan"
        label="Direction Offset"
        v-model.number="sensor.direction_offset"
        max="359"
        :unit="getDegreeUnit"
        :disabled="!authorizeEdit"
        :absolute-value="$_absoluteDirection"
      />
      <!-- Detection Reach -->
      <v-layout row>
        <v-flex grow>
          <d-param-slider
            label="Detection Reach"
            v-model="sensor.reach"
            :min="reachParamsDetect.min"
            :max="reachParamsDetect.max"
            :step="reachParamsDetect.step"
            :unit="convertUnitIfImperial(systemSetting, 'm')"
            :text-input="true"
            :disabled="!authorizeEdit"
          />
        </v-flex>
        <v-flex shrink>
          <v-item-group class="reachPreset">
            <v-item>
              <v-chip
                slot-scope="{ toggle }"
                :selected="sensor.reach == reachParamsDetect.presets.urban"
                @click="
                  () => {
                    toggle()
                    sensor.reach = reachParamsDetect.presets.urban
                  }
                "
                >Urban</v-chip
              >
            </v-item>
            <v-item>
              <v-chip
                slot-scope="{ toggle }"
                :selected="sensor.reach == reachParamsDetect.presets.rural"
                @click="
                  () => {
                    toggle()
                    sensor.reach = reachParamsDetect.presets.rural
                  }
                "
                >Rural</v-chip
              >
            </v-item>
          </v-item-group>
        </v-flex>
      </v-layout>
      <!-- Jamming Reach -->
      <v-layout row v-if="isDsxModelJamming(sensor.model)">
        <v-flex grow>
          <d-param-slider
            label="Disruption Reach"
            v-model="sensor.reach_jamming"
            :min="reachParamsJam.min"
            :max="reachParamsJam.max"
            :step="reachParamsJam.step"
            :unit="convertUnitIfImperial(systemSetting, 'm')"
            :text-input="true"
            :disabled="!authorizeEdit"
          />
        </v-flex>
      </v-layout>

      <v-tooltip right max-width="350" color="sdark" nudge-right="-100">
        <v-switch
            slot="activator"
            class="ma-0"
            label="Display Detections with Unknown Angle"
            color="primary"
            v-model="sensor.show_720_detection"
        />
        <span>Enable/disable the display of nearby detections.</span>
      </v-tooltip>
    </template>
  </d-form>
</template>

<script>
import { FormMixin, SensorMixin } from '@/components/Mixins'
import {
  DsxSensorTypes,
  isDsxModelJamming
} from '@/store/modules/rf_sensors/types'
import { mapGetters } from 'vuex'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'

const props = {
  sensor: {
    type: Object
  },
  sentries: {
    type: Array,
    default: () => []
  },
  sensorTypes: {
    type: Array,
    default: () => DsxSensorTypes
  },
  authorizeEdit: {
    type: Boolean,
    default: true
  },
  showInstallationSelector: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'dronesentryx-form',
  mixins: [FormMixin, SensorMixin],
  data: () => ({
    valid: false,
    selectInstallationError: true,
    selecInstallationSuffix: 'Please select',
    isDsxModelJamming
  }),
  props,
  computed: {
    ...mapGetters('system', ['systemSetting']),
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('sites', ['activeSite']),
    ...mapGetters(['getDegreeUnit']),

    reachParamsDetect() {
      return {
        reach: this.convertValIfImperial(this.systemSetting, 3000, 'm'),
        min: this.convertValIfImperial(this.systemSetting, 500, 'm'),
        max: this.convertValIfImperial(this.systemSetting, 3000, 'm'),
        step: this.convertValIfImperial(this.systemSetting, 500, 'm'),
        presets: {
          urban: this.convertValIfImperial(this.systemSetting, 1000, 'm'),
          rural: this.convertValIfImperial(this.systemSetting, 3000, 'm')
        }
      }
    },
    reachParamsJam() {
      return {
        reach: this.convertValIfImperial(this.systemSetting, 500, 'm'),
        min: this.convertValIfImperial(this.systemSetting, 250, 'm'),
        max: this.convertValIfImperial(this.systemSetting, 750, 'm'),
        step: this.convertValIfImperial(this.systemSetting, 50, 'm'),
      }
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,
    DSXTypeChanged(model) {
      this.$_autoName(model)
    }
  },
  mounted() {
    this.$_updateOffset()
    this.$_initName()

    this.sensor.reach = this.convertValIfImperial(
      this.systemSetting,
      this.sensor.reach,
      'm'
    )

    this.sensor.reach_jamming = this.convertValIfImperial(
      this.systemSetting,
      this.sensor.reach_jamming,
      'm'
    )

    let selectedVal = this.$refs.installationSelect.$props.value
    if (selectedVal === null) {
      console.log('no installation selected')
    } else {
      this.selectInstallationError = false
      this.selecInstallationSuffix = ''
    }
  }
}
</script>

<style>
.reachPreset .v-chip .v-chip__content {
  cursor: pointer !important;
}
</style>
