<template>
  <d-form
    v-model="valid"
    @onSubmit="$emit('onSubmit')"
    @onCancel="
      $router.back()
      $emit('onCancel')
    "
    includeCancel
    v-bind="{ error, status }"
    dataCy="compass-form"
  >
    <v-card flat>
      <sub-header label="General parameters" />
      <v-card-text class="p0">
        <v-select
          v-if="showInstallationSelector"
          label="Installation"
          :items="sentries"
          item-text="name"
          item-value="id"
          v-model="sensor.sentry_id"
          :disabled="!authorizeEdit"
        ></v-select>
        <v-text-field
          label="Name"
          autofocus
          ref="nameField"
          v-model.trim="sensor.name"
          :disabled="!authorizeEdit"
          :rules="[rules.required]"
        />
        <v-text-field
          label="Serial Number"
          v-model.trim="sensor.serial_number"
          v-if="!activePlan"
          :disabled="!authorizeEdit"
          :rules="[rules.required]"
        />
      </v-card-text>
    </v-card>
  </d-form>
</template>

<script>
import SubHeader from '../SubHeader'
import { FormMixin, SensorMixin } from '@/components/Mixins'
import { mapGetters } from 'vuex'

const props = {
  sensor: {
    type: Object
  },
  sentries: {
    type: Array,
    default: () => []
  },
  authorizeEdit: {
    type: Boolean,
    default: true
  },
  sensorTypes: {
    type: Array,
    default: () => [{ value: 'compass', text: 'Compass' }]
  },
  showInstallationSelector: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'compass-form',
  data: () => ({
    valid: false
  }),
  computed: {
    ...mapGetters('plans', ['activePlan'])
  },
  components: {
    SubHeader
  },
  mixins: [FormMixin, SensorMixin],
  props,
  mounted() {
    this.$_initName()
  }
}
</script>
