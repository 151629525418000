<template>
  <div>
    <v-alert
      :value="true"
      outline
      color="primary"
      type="warning"
      class="mb-3 mt-0"
    >
      <!-- TODO: add info regarding radars? -->
      Radars must be configured prior to calibrating the camera.
    </v-alert>
    <v-stepper v-model="step" vertical>
      <v-input
        :error="calibratingCamera !== 'cal-4-0' || disableSubmit"
        style="position: absolute"
      />
      <v-stepper-step :complete="step > 1" step="1">
        Target Acquisition
      </v-stepper-step>
      <v-stepper-content step="1">
        <camera-calibration-step
          :label="
            `Fly ${convertValIfImperial(systemSetting, 150, 'm') +
              convertUnitIfImperial(systemSetting, 'm')} away,
                   ${convertValIfImperial(systemSetting, 25, 'm') +
                     convertUnitIfImperial(systemSetting, 'm')} high`
          "
          :disabled="!allowCalibration || step != 1 || subStep != 0"
          :ticked="step > 1 || subStep > 0"
          hint="Hover the drone within the green band in the direction of the radar to get a consistent detection."
          :error="error.radar"
        />
        <camera-calibration-step
          label="Control the camera and find the drone"
          :disabled="step != 1 || subStep != 1"
          :ticked="step > 1 || subStep > 1"
          hint="Aim at the drone and zoom in to get it centred in the camera feed."
          :error="error.camera"
        />
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2"
        >Tilt Calibration
      </v-stepper-step>
      <v-stepper-content step="2">
        <camera-calibration-step
          :label="
            `Fly to ${convertValIfImperial(systemSetting, targetAltitude, 'm') +
              convertUnitIfImperial(systemSetting, 'm')} high`
          "
          :disabled="step != 2 || subStep != 0"
          :ticked="step > 2 || subStep > 0"
          hint="Move up slowly at constant speed as the camera tracks the drone."
        />
        <v-layout row wrap>
          <v-flex xs6>
            <Plotly
              :data="[
                {
                  ...altitudeData,
                  mode: 'markers',
                  type: 'scattergl',
                  line: {
                    color: '#bcbd22',
                    width: 1
                  },
                  marker: {
                    size: 3
                  },
                  hoverinfo: 'skip'
                }
              ]"
              :layout="{
                autosize: true,
                margin: {
                  l: 45,
                  r: 10,
                  b: 20,
                  t: 20
                },
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                showlegend: false,
                yaxis: {
                  showgrid: true,
                  range: [radarAltitudeAxis.min, radarAltitudeAxis.max],
                  color: '#ccc',
                  gridcolor: 'rgba(0,0,0,0.1)',
                  fixedrange: true,
                  title: { text: 'Altitude' },
                  zeroline: false
                },
                xaxis: {
                  showgrid: false,
                  fixedrange: true,
                  showticklabels: false,
                  zeroline: false
                },
                shapes: [
                  {
                    type: 'rect',
                    xref: 'paper',
                    yref: 'y',
                    x0: 0,
                    y0: targetAltitude - 2,
                    x1: 1,
                    y1: targetAltitude + 2,
                    fillcolor: '#4CAF50',
                    opacity: 0.1,
                    line: { width: 0 }
                  },
                  {
                    type: 'rect',
                    xref: 'paper',
                    yref: 'y',
                    x0: 0,
                    y0: targetAltitude - 52,
                    x1: 1,
                    y1: targetAltitude - 48,
                    fillcolor: '#bcbd22',
                    opacity: 0.1,
                    line: { width: 0 }
                  }
                ]
              }"
              :display-mode-bar="false"
          /></v-flex>
          <v-flex xs6>
            <Plotly
              :data="[
                {
                  ...tiltData,
                  mode: 'markers',
                  type: 'scattergl',
                  line: {
                    color: '#bcbd22',
                    width: 1
                  },
                  marker: {
                    size: 3
                  },
                  hoverinfo: 'skip'
                }
              ]"
              :layout="{
                autosize: true,
                margin: {
                  l: 45,
                  r: 10,
                  b: 45,
                  t: 20
                },
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                showlegend: false,
                yaxis: {
                  showgrid: true,
                  fixedrange: true,
                  range: [-1, 1],
                  color: '#ccc',
                  zeroline: false,
                  gridcolor: 'rgba(0,0,0,0.1)',
                  title: { text: 'Camera tilt' }
                },
                xaxis: {
                  showgrid: true,
                  fixedrange: true,
                  range: [
                    tiltCalibrationResult.min || -1,
                    tiltCalibrationResult.max || 1
                  ],
                  color: '#ccc',
                  zeroline: false,
                  gridcolor: 'rgba(0,0,0,0.1)',
                  title: { text: 'Radar Detection Elevation' }
                },
                shapes: [
                  {
                    type: 'line',
                    x0: tiltCalibrationResult.min || -1,
                    y0: -1,
                    x1: tiltCalibrationResult.max || 1,
                    y1: 1,
                    color: '#4CAF50',
                    line: {
                      width: 10,
                      color: 'rgba(188,189,34,0.1)'
                    }
                  }
                ]
              }"
              :display-mode-bar="false"
            />
          </v-flex>
        </v-layout>
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3"
        >Pan Calibration</v-stepper-step
      >
      <v-stepper-content step="3">
        <camera-calibration-step
          label="Fly 30 degrees sideways"
          :disabled="step != 3 || subStep != 0"
          :ticked="step > 3 || subStep > 0"
          hint="Move left or right slowly at constant speed as the camera tracks the drone."
        />
        <Plotly
          :data="[
            {
              x: panData,
              y: new Array(panData.length).fill(0),
              mode: 'markers',
              type: 'scattergl',
              marker: {
                color: '#bcbd22',
                size: 15
              },
              hoverinfo: 'skip'
            }
          ]"
          :layout="{
            autosize: true,
            height: 100,
            margin: {
              l: 45,
              r: 10,
              b: 45,
              t: 20
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
            showlegend: false,
            yaxis: {
              showgrid: false,
              showticklabels: false,
              fixedrange: true,
              range: [-1, 1],
              zeroline: false,
              gridcolor: 'rgba(0,0,0,0.1)'
            },
            xaxis: {
              showgrid: true,
              fixedrange: true,
              range: cameraPanChartRange,
              color: '#ccc',
              zeroline: false,
              gridcolor: 'rgba(0,0,0,0.1)',
              title: { text: 'Camera Azimuth Offset' }
            },
            shapes: [
              {
                type: 'rect',
                xref: 'x',
                yref: 'paper',
                x0: -180,
                y0: 0,
                x1: -29,
                y1: 1,
                fillcolor: '#4CAF50',
                opacity: 0.2,
                line: { width: 0 }
              },
              {
                type: 'rect',
                xref: 'x',
                yref: 'paper',
                x0: 29,
                y0: 0,
                x1: 180,
                y1: 1,
                fillcolor: '#4CAF50',
                opacity: 0.2,
                line: { width: 0 }
              }
            ]
          }"
          :display-mode-bar="false"
        />
      </v-stepper-content>

      <v-stepper-step step="4">Calibration Check</v-stepper-step>
      <v-stepper-content step="4">
        <!-- <camera-calibration-step
          label="Ensure the calibration works"
          :disabled="step != 4 || subStep != 0"
          :ticked="step > 4 || subStep > 0"
          hint="The camera is going to lose the drone and find it back multiple times. Feel free to move the drone around to make sure the calibration
          is valid."
        /> -->
        <camera-calibration-step
          label="Validate the calibration"
          :disabled="step != 4 || subStep != 0"
          :ticked="true"
          hint="Check the calibration values below and click submit to apply."
        />
        <v-chip color="rgba(255,167,38,0.4)" outline text-color="white">
          <v-avatar color="rgba(255,167,38,0.2)">min</v-avatar>
          {{ tiltCalibrationResult.min }} </v-chip
        ><v-chip color="rgba(255,167,38,0.4)" outline text-color="white">
          <v-avatar color="rgba(255,167,38,0.2)">max</v-avatar>
          {{ tiltCalibrationResult.max }} </v-chip
        ><v-chip color="rgba(255,167,38,0.4)" outline text-color="white">
          <v-avatar color="rgba(255,167,38,0.2)">dir</v-avatar>
          {{ panCalibrationResult }}
        </v-chip>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { Plotly } from 'vue-plotly'
import CameraCalibrationStep from './CameraCalibrationStep'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import { ESiteMode } from '@/store/modules/sites/types'

export default {
  name: 'CameraCalibrationForm',
  components: {
    Plotly,
    CameraCalibrationStep
  },
  props: {
    camera: {
      type: Object
    }
  },
  data: () => ({
    subStep: -1,
    disableSubmit: true,
    step: -1,
    error: {
      radar: false,
      camera: false
    },
    lastCameraPosition: null,
    lastCameraDetection: null,
    lastCameraDetectionTimeout: null,
    lastRadarDetection: null,
    lastRadarDetectionTimeout: null,
    targetAltitude: 75,
    tiltCalibrationResult: {},
    panCalibrationResult: 0,
    altitudeData: { x: [], y: [] },
    radarAltitudeAxis: { min: 45, max: 105 },
    tiltData: { x: [], y: [] },
    panData: []
  }),
  methods: {
    ...mapActions('cameras', ['CAMERA_CONTROL']),

    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    calibrateCamera(action = 'start') {
      this.CAMERA_CONTROL({
        camera_id: this.camera.id,
        command: 'calibrateCamera',
        action
      })
    },
    initSubstep(substep = 0, error = null) {
      this.subStep = -1
      setTimeout(() => {
        if (error) {
          this.error[error] = true // prevents substep = 0 on step change
          this.step = 1
        }
        this.subStep = substep
      }, 1000)
    },
    handleCameraCalibrationUpdate({ key, value }) {
      switch (key) {
        case 'radar_lost':
          if (this.calibratingCamera.startsWith('cal-4')) return
          this.initSubstep(0, 'radar')
          break
        case 'camera_lost':
          if (this.calibratingCamera.startsWith('cal-4')) return
          if (!this.error.radar) {
            this.initSubstep(1, 'camera')
          } else {
            this.error.camera = true
          }
          break
        case 'next_step':
          this.step += 1
          this.calibrateCamera(`start-${this.step}`)
          break
        case 'target_altitude':
          this.targetAltitude = value
          this.radarAltitudeAxis = {
            min: this.targetAltitude - 55,
            max: this.targetAltitude + 5
          }
        case 'radar_detection':
          // Reset radar detection timeout (150ms)
          if (this.lastRadarDetectionTimeout) {
            clearTimeout(this.lastRadarDetectionTimeout)
            this.lastRadarDetectionTimeout = setTimeout(() => {
              this.lastRadarDetection = null
              this.lastRadarDetectionTimeout = null
            }, 150)
          }
          this.lastRadarDetection = value

          if (this.calibratingCamera === 'cal-2-0') {
            this.altitudeData = {
              x: [...this.altitudeData.x, Date.now()],
              y: [...this.altitudeData.y, value.altitude]
            }

            // Drop first altitudeData if length > 50 and altitude offset < 3 (before drone goes higher...)
            if (
              Math.abs(this.targetAltitude - 50 - value.altitude) < 3 &&
              this.altitudeData.x.length > 50
            ) {
              this.altitudeData = {
                x: this.altitudeData.x.slice(1),
                y: this.altitudeData.y.slice(1)
              }
            }
            if (value.altitude < this.radarAltitudeAxis.min) {
              this.radarAltitudeAxis = {
                ...this.radarAltitudeAxis,
                min: value.altitude
              }
            }

            if (value.altitude > this.radarAltitudeAxis.max) {
              this.radarAltitudeAxis = {
                ...this.radarAltitudeAxis,
                max: value.altitude
              }
            }
          }
          break
        case 'camera_detection':
          // Reset camera detection timeout (150ms)
          if (this.lastCameraDetectionTimeout) {
            clearTimeout(this.lastCameraDetectionTimeout)
            this.lastCameraDetectionTimeout = setTimeout(() => {
              this.lastCameraDetection = null
              this.lastCameraDetectionTimeout = null
            }, 150)
          }
          this.lastCameraDetection = value
          break
        case 'tilt_calibration':
          this.tiltCalibrationResult = value.result
          this.tiltData = {
            x: [...this.tiltData.x, value.data.x[value.data.x.length - 1]],
            y: [...this.tiltData.y, value.data.y[value.data.y.length - 1]]
          }
          break
        case 'pan_calibration':
          this.panCalibrationResult = value.result
          break
        case 'camera_angle':
          this.panData = [...this.panData, value]
          break
      }
    }
  },
  computed: {
    ...mapGetters('system', ['systemSetting']),
    ...mapState('detection', ['selectedDetections']),
    ...mapState('cameras', ['calibratingCamera', 'camerasSet']),
    ...mapGetters('sites', ['activeSite']),
    allowCalibration() {
      return this.activeSite.mode === ESiteMode.Calibration
    },
    realTimeCameraPosition() {
      const { pan, tilt, zoom } = this.camerasSet[this.camera.id] || {}
      return { pan, tilt, zoom }
    },
    cameraPanChartRange() {
      const lastAngle = this.panData.slice(-1)[0] || 0
      return [lastAngle - 30, lastAngle + 30]
    }
  },
  beforeDestroy() {
    this.$emitter.off(
      'cameraCalibrationUpdate',
      this.handleCameraCalibrationUpdate
    )
    this.calibrateCamera('stop')
  },
  mounted() {
    setTimeout(() => (this.step = 1), 500)
    this.$emitter.on(
      'cameraCalibrationUpdate',
      this.handleCameraCalibrationUpdate
    )
  },
  watch: {
    realTimeCameraPosition(v) {
      if (!this.lastCameraPosition) this.lastCameraPosition = v
      const { pan, tilt } = v

      // Do not consider camera update if there hasn't been any recent radar and camera detections
      if (!this.lastRadarDetection || !this.lastCameraDetection) {
        return
      }

      switch (this.calibratingCamera) {
        // Tilt Calibration
        case 'cal-2-0':
          if (tilt != this.lastCameraPosition.tilt) {
            //TODO check this.lastCameraDetection y close to 50 to decide whether it should be used
            this.lastCameraPosition.tilt = tilt
            this.$emitter.emit('cameraCalibrationUpdate', {
              key: 'tilt_update',
              value: { detection: this.lastRadarDetection, tilt }
            })
          }
          break

        case 'cal-3-0':
          // Pan Calibration
          if (pan != this.lastCameraPosition.pan) {
            //TODO check this.lastCameraDetection x close to 50 to decide whether it should be used

            // //TODO: Experiment - BOSCH ONLY!!
            // console.log(
            //   'this.lastCameraDetection.x',
            //   this.lastCameraDetection.x
            // )
            // const adjustedPan =
            //   this.lastCameraPosition.pan +
            //   (this.lastCameraPosition.pan - pan) / 2
            // console.log(
            //   `Pan from ${this.lastCameraPosition.pan} to ${pan}, should use ${adjustedPan}?`
            // )
            this.lastCameraPosition.pan = pan
            this.$emitter.emit('cameraCalibrationUpdate', {
              key: 'pan_update',
              value: { detection: this.lastRadarDetection, pan } //pan: adjustedPan
            })
          }
          // TODO: For Bosch, pan change = halfway, e.g. 0.2 to 0.3 when reaching 0.25
          break
      }
    },
    step(v) {
      // invalidate camera error when getting to step 2
      if (v == 2) {
        this.error = { ...this.error, camera: false }
      }
      // initialise substep 0 with animation when getting to a new step
      if (!this.error.radar && !this.error.camera) {
        this.initSubstep()
      }
    },
    selectedDetections(v) {
      // go from calibration step 1.0 to step 1.1 when a detection is selected
      if (v.length == 1 && this.calibratingCamera === 'cal-1-0')
        this.subStep += 1
    },
    subStep(v) {
      // subStep -1 used for animation purposes, ignored otherwise
      if (v === -1) return
      const calibrationStep = `cal-${this.step}-${v}`
      this.$store.dispatch('cameras/CALIBRATE_CAMERA', calibrationStep)
      switch (calibrationStep) {
        case 'cal-1-1':
          // invalidate radar error when getting to step 1.1
          this.error = { ...this.error, radar: false }
          // start DroneOptID tracking
          return this.calibrateCamera()
        case 'cal-4-0':
          // TODO: change duration once calibration testing is added
          setTimeout(() => (this.disableSubmit = false), 5000)
      }
    }
  }
}
</script>
