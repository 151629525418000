<template>
  <d-form
    v-model="valid"
    @onSubmit="$emit('onSubmit')"
    @onCancel="
      $router.back()
      $emit('onCancel')
    "
    includeCancel
    dataCy="discovair-form"
  >
    <v-card flat>
      <sub-header label="General Parameters" />
      <v-card-text>
        <div data-cy="installation" v-if="showInstallationSelector">
          <v-select
            label="Installation"
            :items="sentries"
            item-text="name"
            item-value="id"
            ref="installationSelect"
            v-model="sensor.sentry_id"
            @change="$_updatedSentry"
            :error=selectInstallationError
            :suffix=selecInstallationSuffix
          ></v-select>
        </div>
        <v-text-field
          label="Name"
          autofocus
          ref="nameField"
          v-model.trim="sensor.name"
          :rules="[rules.required]"
          data-cy="name"
        />
        <template v-if="!activePlan">
          <v-text-field
            label="Serial Number"
            v-model.trim="sensor.serial_number"
            :rules="[rules.required]"
            data-cy="serial"
          />
          <v-text-field
            label="Detector Identifier"
            v-model.trim="sensor.detector_identifier"
            :rules="[rules.required]"
            data-cy="detector-id"
          />
        </template>
        <d-param-slider
          v-if="activePlan"
          label="Direction"
          v-model.number="sensor.direction"
          max="359"
          :unit="getDegreeUnit"
        />
        <d-param-slider
          v-else
          label="Direction Offset"
          v-model.number="sensor.direction_offset"
          max="359"
          :unit="getDegreeUnit"
          :absolute-value="$_absoluteDirection"
        />
        <v-layout row>
          <v-flex shrink>
            <v-item-group class="reachPreset">
              <v-item> Reach: </v-item>
              <v-item>
                <v-chip
                  slot-scope="{ toggle }"
                  :selected="sensor.reach === reachMax.urban"
                  @click="
                    () => {
                      toggle()
                      sensor.reach = reachMax.urban
                    }
                  "
                  >Urban</v-chip
                >
              </v-item>
              <v-item>
                <v-chip
                  slot-scope="{ toggle }"
                  :selected="sensor.reach === reachMax.rural"
                  @click="
                    () => {
                      toggle()
                      sensor.reach = reachMax.rural
                    }
                  "
                  >Rural</v-chip
                >
              </v-item>
            </v-item-group>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </d-form>
</template>

<script>
import { SensorMixin, FormMixin } from '@/components/Mixins'
import SubHeader from '../SubHeader'
import utils from '@/utils/utils'
import { mapGetters } from 'vuex'

const props = {
  sensor: {
    type: Object
  },
  compasses: {
    type: Array,
    default: () => []
  },
  hasCompass: {
    type: Boolean,
    default: false
  },
  sentries: {
    type: Array,
    default: () => []
  },
  sensorTypes: {
    type: Array,
    default: () => [{ value: 'discovair', text: 'Discovair' }]
  }
}

export default {
  name: 'discovair-form',
  components: {
    SubHeader
  },
  mixins: [SensorMixin, FormMixin],
  props,
  data: () => ({
    valid: false,
    selectInstallationError: true,
    selecInstallationSuffix: "Please select",
    reachMax: {
      urban: 150,
      rural: 500
    }
  }),
  computed: {
    ...mapGetters(['getDegreeUnit']),
    ...mapGetters('plans', ['activePlan']),
    sensorType() {
      return this.sensor['sensor_type'] || 'discovair'
    },
    sensorIP() {
      return this.sensor.ip && typeof this.sensor.ip.addr === 'number'
        ? utils.intToIP(this.sensor.ip.addr)
        : 'Not connected'
    }
  },
  mounted() {
    this.$_updateOffset()
    this.$_initName()

    let selectedVal = this.$refs.installationSelect.$props.value
    if (selectedVal === null) {
      console.log('no installation selected')
    } else {
      console.log(this.$refs.installationSelect.$props.value)
      this.selectInstallationError = false
      this.selecInstallationSuffix = ""
    }
  }
}
</script>
<style>
.reachPreset .v-chip .v-chip__content {
  cursor: pointer !important;
}
</style>
