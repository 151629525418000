var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-form',_vm._b({attrs:{"includeCancel":"","dataCy":"rf-sensor-form"},on:{"onCancel":function($event){_vm.$router.back()
    _vm.$emit('onCancel')},"onSubmit":function($event){return _vm.$emit('onSubmit')},"advancedExpanded":function($event){_vm.expanded = true}},scopedSlots:_vm._u([{key:"general",fn:function(){return [(_vm.showInstallationSelector)?_c('v-select',{ref:"installationSelect",attrs:{"label":"Installation","items":_vm.sentries,"item-text":"name","item-value":"id","disabled":!_vm.authorizeEdit,"error":_vm.selectInstallationError,"suffix":_vm.selectInstallationSuffix},on:{"change":function($event){return _vm.$_updatedSentry()}},model:{value:(_vm.sensor.sentry_id),callback:function ($$v) {_vm.$set(_vm.sensor, "sentry_id", $$v)},expression:"sensor.sentry_id"}}):_vm._e(),_c('v-text-field',{ref:"nameField",attrs:{"label":"Name","autofocus":"","disabled":!_vm.authorizeEdit,"rules":[_vm.rules.required]},model:{value:(_vm.sensor.name),callback:function ($$v) {_vm.$set(_vm.sensor, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"sensor.name"}}),_c('div',{attrs:{"data-cy":"rf-type"}},[_c('v-select',{attrs:{"data-cy":"rf-type-select","label":"RF Type","items":_vm.currentSensorTypes,"disabled":!_vm.authorizeEdit},on:{"change":_vm.RFTypeChanged},model:{value:(_vm.sensor.model),callback:function ($$v) {_vm.$set(_vm.sensor, "model", $$v)},expression:"sensor.model"}})],1),(!_vm.activePlan)?_c('v-text-field',{attrs:{"label":"Serial Number","disabled":!_vm.authorizeEdit,"rules":[_vm.rules.required, _vm.rules.serialNumberLength],"error":_vm.sensor.serial_number
          ? _vm.sensor.serial_number.length != 13
            ? true
            : false
          : false},model:{value:(_vm.sensor.serial_number),callback:function ($$v) {_vm.$set(_vm.sensor, "serial_number", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"sensor.serial_number"}}):_vm._e(),(!_vm.activePlan && !['rf_patrol'].includes(_vm.sensor.model))?_c('d-param-slider',{attrs:{"label":"Direction Offset","max":"359","unit":_vm.getDegreeUnit,"disabled":!_vm.authorizeEdit,"absolute-value":_vm.$_absoluteDirection},model:{value:(_vm.sensor.direction_offset),callback:function ($$v) {_vm.$set(_vm.sensor, "direction_offset", _vm._n($$v))},expression:"sensor.direction_offset"}}):_vm._e(),_c('v-layout',{attrs:{"d-flex":"","flex-column":""}},[_c('v-flex',{attrs:{"grow":""}},[_c('d-param-slider',{attrs:{"label":"Display Range","min":_vm.reachParams.min,"max":_vm.reachParams.max,"step":_vm.reachParams.step,"unit":_vm.convertUnitIfImperial(_vm.systemSetting, 'm'),"disabled":!_vm.authorizeEdit},model:{value:(_vm.sensor.reach),callback:function ($$v) {_vm.$set(_vm.sensor, "reach", $$v)},expression:"sensor.reach"}})],1),_c('v-item-group',{staticClass:"reachPreset"},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var toggle = ref.toggle;
return _c('v-chip',{attrs:{"selected":_vm.sensor.reach ===
                _vm.convertValIfImperial(_vm.systemSetting, _vm.reachParams.presets.urban, 'm')},on:{"click":function () {
                toggle()
                _vm.sensor.reach = _vm.convertValIfImperial(
                  _vm.systemSetting,
                  _vm.reachParams.presets.urban,
                  'm'
                )
              }}},[_vm._v("Urban")])}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var toggle = ref.toggle;
return _c('v-chip',{attrs:{"selected":_vm.sensor.reach ===
                _vm.convertValIfImperial(_vm.systemSetting, _vm.reachParams.presets.rural, 'm')},on:{"click":function () {
                toggle()
                _vm.sensor.reach = _vm.convertValIfImperial(
                  _vm.systemSetting,
                  _vm.reachParams.presets.rural,
                  'm'
                )
              }}},[_vm._v("Rural")])}}])})],1)],1),(_vm.activePlan && !['rf_zero', 'rf_patrol'].includes(_vm.sensor.model))?_c('d-param-slider',{attrs:{"label":"Direction","unit":_vm.getDegreeUnit,"disabled":!_vm.authorizeEdit,"max":"359.9","step":"0.1"},model:{value:(_vm.sensor.direction),callback:function ($$v) {_vm.$set(_vm.sensor, "direction", _vm._n($$v))},expression:"sensor.direction"}}):_vm._e()]},proxy:true},(!_vm.activePlan)?{key:"advanced",fn:function(){return [(_vm.showDetectionVisualisation)?_c('div',[_c('div',[_c('div',{staticClass:"grey--text mb-3"},[_vm._v("Detection Visualisation")]),(_vm.sensor.model === 'rf_two')?_c('v-tooltip',{attrs:{"right":"","max-width":"430","color":"sdark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"outside-detections-switch"},on),[_c('div',[_c('v-switch',{staticClass:"my-0 mx-0 px-0 py-0",staticStyle:{"display":"block"},attrs:{"type":"Boolean","color":"primary","disabled":!_vm.authorizeEdit},model:{value:(_vm.sensor.show_outside_detections),callback:function ($$v) {_vm.$set(_vm.sensor, "show_outside_detections", $$v)},expression:"sensor.show_outside_detections"}})],1),_c('div',{staticClass:"preview-wrapper"},[_c('div',[_vm._v("Show outside detections")]),_c('div',{class:("preview-button-text grey--text " + (_vm.previewingDetection ? 'disabled-preview' : '')),on:{"click":_vm.previewOutsideDetection}},[_vm._v(" Preview ")])])])]}}],null,false,889369497)},[_c('span',[_vm._v(" Show indicator when a detection is made outside of sensor field of view ")])]):_vm._e(),_c('DetectionVisualization',{attrs:{"shown":_vm.expanded,"sensor":_vm.sensor,"sector":_vm.sensor.show_sectors,"showOutsideDetection":_vm.previewingDetection,"line":_vm.sensor.show_sector_as_line},on:{"onChange":_vm.detectionVisualChanged}})],1)]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},'d-form',{ error: _vm.error, status: _vm.status },false))}
var staticRenderFns = []

export { render, staticRenderFns }